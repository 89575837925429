import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Error } from './error';
import { Root } from './root';

// Ensure we have all Bootstrap styles everywhere
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './index.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
  }
]);

export function App() {
  return <RouterProvider router={router}/>;
}
